<div id="app-wrapper">
   <!--Hammer Modal to appear if user is signed in on multiple devices. -->
   @if (showHammerModal) {
      <div class="hammer-modal-background">
         <div class="hammer-container">
            <lim-ui-modal [showWatermark]="true" class="hammer-modal">
               <div class="hammer-content">
                  <h1 class="text-center lim-ui-fonts-h1">{{ lang()?.Whoops }}</h1>

                  <div class="hammer-body">
                     <p class="lim-ui-fonts-body">
                        <strong>{{ userName }}</strong>
                        {{ lang()?.LoggedIntoAnotherDevice }}
                        {{ lang()?.YouWillBeLoggedOut }}
                     </p>

                     <div class="countdown-text-container">
                        <h2 class="lim-ui-fonts-h1">{{ timeLeft }}s</h2>
                     </div>
                     <div class="hammerButtons">
                        <lim-ui-primary-button
                           [isLoginButton]="true"
                           (click)="stopHammerClick()"
                           >{{ lang()?.StayLoggedIn }}</lim-ui-primary-button
                        >
                        <lim-ui-outlined-button [isLoginButton]="true" (click)="logout()"
                           >{{ lang()?.signOut }}
                        </lim-ui-outlined-button>
                     </div>
                  </div>
               </div>
            </lim-ui-modal>
         </div>
      </div>
   }

   @if ((showOfflineBanner$ | async) === true) {
      <connection-lost />
   }

   @if (mobile && showMobileSideMenu) {
      <div
         class="invisible-backdrop"
         [ngClass]="{ 'show-mobile-side-menu': showMobileSideMenu }"
         (click)="toggleShowMobileSideMenu()"
      ></div>
   }

   @if (mobile) {
      <div
         class="mobile-side-menu"
         [ngClass]="{
            'show-mobile-side-menu': showMobileSideMenu,
            'do-not-pull-to-refresh': showMobileSideMenu,
         }"
      >
         <mobile-global-nav (closeSideMenu)="toggleShowMobileSideMenu()" />
      </div>
   }

   <div class="alert-area">
      @for (alert of alerts$ | async; track alert) {
         <lim-ui-alert
            [alertType]="alert.type"
            (dismissedEvent)="closeAlert(alert)"
            (linkClickedEvent)="alertLinkClicked(alert.linkInfo?.link)"
            [dismissible]="alert.dismissible"
            [title]="alert.alertTitle"
            [linkInfo]="alert?.linkInfo"
         >
            <span [limbleHtml]="alert.msg"></span>
         </lim-ui-alert>
      }
   </div>

   <div
      class="page-container"
      id="page-container"
      [ngClass]="{
         'logged-in-as-guest-top': currentUser === undefined,
         'hide-sidebar-spacing': mobile,
      }"
   >
      @if (currentUser !== undefined) {
         @if (!mobile) {
            <app-header />
         } @else {
            <mobile-header (menuClick)="toggleShowMobileSideMenu()" />
         }
      }
      <div class="page-content" [ngClass]="{ pageContentMobileOverride: mobile }">
         @if (showDesktopGlobalSideNav()) {
            <nav class="page-leftbar" role="navigation" autoscroll="false">
               <global-nav [showFeatureRequestBoard]="showFeatureRequestBoard" />
            </nav>
         }
         <div class="route-container">
            @if (lang()) {
               <router-outlet />
            }
         </div>
      </div>
      @if (mobile && currentUser !== undefined) {
         <mobile-footer />
      }
      @if (!mobile) {
         <ng-container [ngTemplateOutlet]="dataLimitingBanner" />
      }
   </div>
   @if (mobile) {
      <ng-container [ngTemplateOutlet]="dataLimitingBanner" />
   }
</div>

<ng-template #dataLimitingBanner>
   <div class="bottom-banner">
      @if (currentUser?.userInfo?.dataLimitingTimestamp !== null) {
         <span>
            <span class="data-limiting-warning">
               <lim-ui-icon icon="triangleExclamation" iconColor="danger" />
               {{ dataLimitingMessage }}
            </span>
         </span>
      }
   </div>
</ng-template>
