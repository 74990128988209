<div class="user-management-popover">
   <div class="lim-ui-show-on-mobile mobile-title-container" titleContent>
      <div class="mobile-title">
         <div class="mobile-title-subcontainer">
            <lim-ui-icon icon="user" [iconSize]="'medium'" />
            <div class="title-text">{{ lang()?.Profile }}</div>
         </div>
         <lim-ui-icon
            (click)="close()"
            class="mobile-title-close"
            icon="xmarkLargeRegular"
            [iconSize]="'medium-large'"
         />
      </div>
   </div>
   <header class="header">
      <div class="user-info-container">
         @if (currentUser) {
            <user-image
               [user]="currentUser"
               size="medium-large"
               class="sidebar-user-image"
            />
         }
         @if (currentUser) {
            <div class="current-user-info">
               <h2 class="remove-h-margins">
                  {{ lang()?.Hi }}, {{ currentUser.userInfo.fName }}!
               </h2>
               <p>{{ lang()?.LoggedInAs }}</p>
               <p>
                  <a href="mailto:{{ currentUser.userInfo.userEmail }}">{{
                     currentUser.userInfo.userEmail
                  }}</a>
               </p>
               <p class="small-text">{{ lang()?.Version }} {{ version() }}</p>
            </div>
         }
      </div>
      @if (planName) {
         <div class="pricing-plan-buttons-container">
            <div class="pricing-plan-badge">
               <span>{{ planName }}</span>
            </div>
            @if (isBasicPlan$ | async) {
               <lim-ui-secondary-button
                  [classNames]="'full-width'"
                  class="pricing-upgrade-button"
                  (click)="openUpgradePage()"
                  icon="boltRegular"
               >
                  {{ lang()?.Upgrade }}
               </lim-ui-secondary-button>
            }
         </div>
      }
   </header>
   <div class="profile-options-container scroll-height-inheritance">
      <lim-ui-scroll-container [thin]="true" (refreshEvent)="refreshPage()">
         <ul class="profile-options">
            @if (currentUser && currentUser.workOrderUser != 1) {
               <li (click)="popUser(); close()">
                  {{ lang()?.EditProfile }}
               </li>
            }
            @if (
               currentUser &&
               currentUser.workOrderUser != 1 &&
               currentUser.userInfo?.ssoUser != 1
            ) {
               <li (click)="changePassword(); close()">
                  {{ lang()?.changePassword }}
               </li>
            }
            @if (billingCred && !demo) {
               <li [routerLink]="['/subscription']" (click)="close()">
                  {{ lang()?.Subscription }}
               </li>
            }
            @if (
               currentUser && currentUser.workOrderUser != 1 && showFeatureRequestBoard
            ) {
               <li (click)="openFeatureRequestBoard(); close()">
                  {{ lang()?.ProductFeedbackBoard }}
               </li>
            }
            @if (currentUser && currentUser.workOrderUser != 1) {
               <li (click)="openHelpCenter(); close()">
                  {{ lang()?.HelpCenter }}
               </li>
            }
            @if (
               currentUser && currentUser.workOrderUser != 1 && isLiteEnabled() === true
            ) {
               <li class="sync-tasks-button">
                  @if (
                     (refreshService.stateOfRefresh() | async) === true ||
                     litePrepState() === OfflinePrepState.InProgress
                  ) {
                     <div id="sync-in-progress">
                        <lim-ui-loading-animation animationSize="extra-small" />
                        <span>{{ lang()?.SyncingData }}</span>
                     </div>
                  } @else if ((refreshService.stateOfRefresh() | async) === false) {
                     @if (
                        litePrepState() === OfflinePrepState.Prepared ||
                        litePrepState() === OfflinePrepState.PreparedButLimited
                     ) {
                        <div
                           (click)="navigateToLite()"
                           data-log="accountManagement-goOffline"
                           class="resync-box"
                        >
                           <p>
                              <span>{{ lang()?.GoOffline }}</span>
                              <lim-ui-icon
                                 icon="circleCheckRegular"
                                 iconSize="small"
                                 iconColor="success"
                              />
                           </p>
                           @if (litePrepState() === OfflinePrepState.PreparedButLimited) {
                              <p class="small-sync-text">
                                 <span>{{ lang()?.LiteDataLimitReachedWarning }}</span>
                              </p>
                           }
                        </div>
                     } @else if (litePrepState() === OfflinePrepState.Failed) {
                        <div (click)="resyncCache()" class="resync-box">
                           <p class="text-group">
                              <span>{{ lang()?.SyncFailed }}</span>
                              <lim-ui-icon
                                 icon="circleXmark"
                                 iconSize="small"
                                 iconColor="danger"
                              />
                           </p>
                           <p class="small-sync-text">
                              <span>{{ lang()?.TapToResync }}</span>
                           </p>
                        </div>
                     }
                  }
               </li>
            }

            @if (
               currentUser &&
               currentUser.workOrderUser != 1 &&
               dateUtil.isWithin30Days(currentUser.userInfo?.canReviewTimestamp)
            ) {
               <li (click)="startG2Review(); close()">
                  {{ lang()?.LeaveReview }}
               </li>
            }
            @if (currentUser && currentUser.workOrderUser != 1 && !demo) {
               <li class="logout-button" (click)="logout(); close()">
                  {{ lang()?.signOut }}
               </li>
            }
            <li class="pp-tos" (click)="close()">
               <a href="https://limblecmms.com/privacy.php" target="_BLANK">
                  Privacy Policy
               </a>
               &bull;
               <a href="https://limblecmms.com/terms-of-service.php" target="_BLANK">
                  Terms of Service
               </a>
            </li>
         </ul>
      </lim-ui-scroll-container>
   </div>
</div>
