import { NgClass } from "@angular/common";
import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, EventEmitter, Output, computed } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router, RouterLink } from "@angular/router";
import {
   BadgeComponent,
   IconComponent,
   ModalService,
   MinimalIconButtonComponent,
   PopoverDirective,
   UpsellPopover,
   isNativeMobileApp,
} from "lim-ui";
import { Subscription, filter } from "rxjs";
import { PickAssets } from "src/app/assets/components/pickAssetsModal/pickAssets.modal.component";
import { PopAsset } from "src/app/assets/components/popAssetModal/popAsset.modal.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { ManageAssetServ } from "src/app/assets/services/manageAssetServ";
import { StartWOService } from "src/app/dashboards/components/dashboard-list-view-tasks/start-wo/start-wo.service";
import { ManageLang } from "src/app/languages/services/manageLang";
import { PickLocationsModal } from "src/app/locations/components/pickLocationsModal/pickLocations.modal.component";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { PopPart } from "src/app/parts/components/popPartsModal/popPart.modal.component";
import {
   PartsFacadeService,
   type AddPartModalData,
} from "src/app/parts/components/shared/parts-facade-service/parts-facade-service.service";
import { PickPOs } from "src/app/purchasing/pos/pickPOsModal/pickPOs.modal.component";
import { PoComponent } from "src/app/purchasing/pos/poWrapper/po.wrapper.component";
import { ReceiveMobilePo } from "src/app/purchasing/pos/receiveMobilePOModal/receiveMobilePo.modal.component";
import {
   locationRoutes,
   settingRoutes,
} from "src/app/shared/components/app-housing.routes";
import { GlobalSearchComponent } from "src/app/shared/components/global/globalSearch/globalSearch.component";
import { QrCodeService } from "src/app/shared/components/global/qrCodeButton/qr-code.service";
import { QRCodeButton } from "src/app/shared/components/global/qrCodeButton/qrCodeButton.component";
import { AlertService } from "src/app/shared/services/alert.service";
import { EnvRoutesService } from "src/app/shared/services/envRoutes.service";
import type { IsFeatureEnabledMap } from "src/app/shared/services/feature-flags/feature.types";
import { ManageFeatureFlags } from "src/app/shared/services/feature-flags/manageFeatureFlags";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ParamsService } from "src/app/shared/services/params.service";
import { WhiteLabelService } from "src/app/shared/services/white-label/white-label.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { ManageSubscription } from "src/app/subscriptions/services/manageSubscription";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageLogin } from "src/app/users/services/manageLogin";
import { ManageUser } from "src/app/users/services/manageUser";
import { PickVendors } from "src/app/vendors/components/pickVendorsModal/pickVendors.modal.component";
import { PopVendor } from "src/app/vendors/components/popVendorModal/popVendor.modal.component";
import { ManageVendor } from "src/app/vendors/services/manageVendor";

@Component({
   selector: "mobile-global-nav",
   templateUrl: "./mobileGlobalNav.wrapper.component.html",
   styleUrls: ["./mobileGlobalNav.wrapper.component.scss"],
   standalone: true,
   imports: [
      MinimalIconButtonComponent,
      NgClass,
      BadgeComponent,
      IconComponent,
      PopoverDirective,
      UpsellPopover,
      RouterLink,
      QRCodeButton,
   ],
})
export class MobileGlobalNav implements OnInit, OnDestroy {
   @Output() public readonly closeSideMenu = new EventEmitter();
   public navActive;
   public showQRCode;
   public currentUser;
   public cred35;
   public cred41;
   public cred42;
   public cred43;
   public cred45;
   public cred60;
   public cred96;
   public cred110;
   public cred111;
   public cred198;
   public featureMaps;
   public poweredBy;
   public loaded;
   public currentCustomDashboard;
   public currentMap;
   public loginWatchVarSub: any;
   public usingCustomLogo: boolean = false;
   private readonly updateCustomLogoSub: Subscription;
   public customLogoURL: string | undefined;
   public crossOriginAnonymous: boolean;
   public logoURLDark;
   public logoURLDarkCSS;

   protected featureUnlimitedVendors: boolean = false;
   protected featureUnlimitedParts: boolean = false;
   protected featureUnlimitedPOs: boolean = false;
   protected featureLimitedNumber: boolean = false;
   protected showLookupVenderOption: boolean = false;
   protected showLookupPartsOption: boolean = false;
   protected showLookupPOsOption: boolean = false;
   protected showReceivePOItemsOption: boolean = false;
   protected isBasicPlan: boolean = false;

   private isBasicPlanSub: Subscription = new Subscription();
   private readonly manageFeatureFlagsSub: Subscription;

   private readonly modalService = inject(ModalService);
   private readonly router = inject(Router);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageLogin = inject(ManageLogin);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageAssetServ = inject(ManageAssetServ);
   private readonly alertService = inject(AlertService);
   private readonly manageVendor = inject(ManageVendor);
   private readonly manageObservables = inject(ManageObservables);
   private readonly manageUser = inject(ManageUser);
   private readonly paramsService = inject(ParamsService);
   private readonly route = inject(ActivatedRoute);
   private readonly manageFeatureFlags = inject(ManageFeatureFlags);
   private readonly credService = inject(CredService);
   private readonly envRoutesService = inject(EnvRoutesService);
   private readonly manageSubscription = inject(ManageSubscription);
   private readonly whiteLabelService = inject(WhiteLabelService);
   private readonly qrCodeService = inject(QrCodeService);
   private readonly partsFacadeService = inject(PartsFacadeService);
   private readonly startWOService = inject(StartWOService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.crossOriginAnonymous = /Chrome|FireFox/.test(window.navigator.userAgent);
      this.navActive = [];
      this.router.events
         .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
         .subscribe(() => {
            const locationID = Number(
               this.route.firstChild?.snapshot.paramMap.get("locationID"),
            );
            const routeName = this.route.firstChild?.snapshot.data.routeName;
            this.setNavActive(routeName, locationID);
         });
      const routeName = this.route.firstChild?.snapshot.data.routeName;
      this.setNavActive(routeName, "NA");
      this.updateCustomLogoSub = this.manageLogin.updateCustomLogo$.subscribe(
         (newValue) => {
            this.usingCustomLogo = Boolean(newValue);
         },
      );

      this.manageFeatureFlagsSub = this.manageFeatureFlags.features$.subscribe(
         (isFeatureEnabledMap: IsFeatureEnabledMap) => {
            this.featureUnlimitedVendors = isFeatureEnabledMap.featureUnlimitedVendors;

            this.featureUnlimitedParts = isFeatureEnabledMap.featureUnlimitedParts;

            this.featureUnlimitedPOs = isFeatureEnabledMap.featureUnlimitedPOs;

            this.featureLimitedNumber = isFeatureEnabledMap.featureLimitedNumber;
         },
      );
   }

   private setNavActive(
      routeName: string | undefined,
      locationID: string | number | undefined,
   ) {
      if (routeName === undefined) {
         //Not sure when this could happen. Seems impossible. But we have to check for typescript.
         return;
      }
      let temp: Array<string>;
      if (locationRoutes.includes(routeName)) {
         temp = ["loc", String(locationID), routeName];
      } else if (settingRoutes.includes(routeName)) {
         temp = ["settings", routeName];
      } else {
         temp = [routeName];
      }
      this.navActive = temp;
   }

   public ngOnInit() {
      this.showQRCode = isNativeMobileApp(); //IMPORTANT for QR codes we don't use the general check mobile Util function;
      this.logoURLDark = this.whiteLabelService.logoUrlDark();
      this.logoURLDarkCSS = this.whiteLabelService.logoCssDark();
      this.currentUser = "none";

      this.isBasicPlanSub = this.manageSubscription.isBasicPlan$.subscribe(
         (isBasicPlan) => {
            this.isBasicPlan = isBasicPlan;
            this.setPermissions();
         },
      );

      this.loginWatchVarSub = this.manageUser.currentUserChanges$
         .pipe(filter((currentUser) => currentUser !== undefined))
         .subscribe((currentUser) => {
            if (currentUser.userInfo) {
               this.currentUser = currentUser;
               this.featureMaps = currentUser.userInfo.featureMaps;

               this.cred35 = this.credService.checkCredAnywhere(
                  this.credService.Permissions.ViewAllOpenTasks,
               );
               this.cred41 = this.credService.checkCredAnywhere(
                  this.credService.Permissions.ViewCustomDashboards,
               );
               this.cred42 = this.credService.checkCredAnywhere(
                  this.credService.Permissions.ViewMyOpenTasks,
               );
               this.cred43 = this.credService.checkCredAnywhere(
                  this.credService.Permissions.StartNewTasks,
               );
               this.cred45 = this.credService.checkCredAnywhere(
                  this.credService.Permissions.ReportAProblem,
               );
               this.cred60 = this.credService.checkCredAnywhere(
                  this.credService.Permissions.AddAssets,
               );
               this.cred96 = this.credService.checkCredAnywhere(
                  this.credService.Permissions.ViewSubscription,
               );
               this.cred110 = this.credService.checkCredAnywhere(
                  this.credService.Permissions.ViewLookupAnAsset,
               );
               this.cred111 = this.credService.checkCredAnywhere(
                  this.credService.Permissions.ViewLookupAPart,
               );
               this.cred198 = this.credService.checkCredAnywhere(
                  this.credService.Permissions.ViewMaps,
               );

               this.currentCustomDashboard =
                  this.currentUser.userInfo.userUIPreferences.currentDashboardID || 0;
               this.setCustomLogo(this.currentUser.userInfo.customLogoFileName);

               this.currentMap =
                  this.currentUser.userInfo.userUIPreferences.currentMapID || 0;

               this.setPermissions();
            }
         });

      this.poweredBy = this.whiteLabelService.shouldShowPoweredBy();
   }

   public ngOnDestroy() {
      this.manageObservables.removeManySubscriptions([this.loginWatchVarSub]);
      this.updateCustomLogoSub.unsubscribe();
      this.isBasicPlanSub.unsubscribe();
      this.manageFeatureFlagsSub.unsubscribe();
   }

   private setPermissions() {
      const isSuperUser =
         this.manageUser.getCurrentUser()?.userInfo?.isSuperUser ?? false;

      this.showLookupVenderOption =
         this.isBasicPlan ||
         ((this.featureUnlimitedVendors || this.featureLimitedNumber) &&
            this.credService.checkCredAnywhere(
               this.credService.Permissions.ViewLookUpAVendor,
            )) ||
         (!this.featureUnlimitedVendors && !this.featureLimitedNumber && isSuperUser);

      this.showLookupPartsOption =
         this.isBasicPlan ||
         ((this.featureUnlimitedParts || this.featureLimitedNumber) &&
            this.credService.checkCredAnywhere(
               this.credService.Permissions.ViewLookupAPart,
            )) ||
         (!this.featureUnlimitedParts && !this.featureLimitedNumber && isSuperUser);

      this.showLookupPOsOption =
         this.isBasicPlan ||
         ((this.featureUnlimitedPOs || this.featureLimitedNumber) &&
            (this.credService.checkCredAnywhere(this.credService.Permissions.ManagePOs) ||
               this.credService.checkCredAnywhere(
                  this.credService.Permissions.StartAPO,
               ) ||
               this.credService.checkCredAnywhere(
                  this.credService.Permissions.ReceivePOItems,
               ))) ||
         (!this.featureUnlimitedPOs && !this.featureLimitedNumber && isSuperUser);

      this.showReceivePOItemsOption =
         this.isBasicPlan ||
         ((this.featureUnlimitedPOs || this.featureLimitedNumber) &&
            this.credService.checkCredAnywhere(
               this.credService.Permissions.ReceivePOItems,
            )) ||
         (!this.featureUnlimitedPOs && !this.featureLimitedNumber && isSuperUser);
   }

   lookUpAsset = () => {
      let singleLocation;
      if (this.manageLocation.getLocations().length == 1) {
         singleLocation = this.manageLocation.getLocations()[0].locationID;
      } else {
         singleLocation = 0;
      }

      const instance = this.modalService.open(PickAssets);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: "",
            title: this.lang().lookUpAnAsset,
            data: {
               singleLocation: singleLocation,
               selectOne: true,
               restrictToCred: false,
               iDontKnowOption: false,
            },
         },
      };

      instance.result.then((asset) => {
         if (asset) {
            const fullAsset = this.manageAsset.getAsset(asset.assetID);
            if (!fullAsset) {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
               return;
            }

            const instance2 = this.modalService.open(PopAsset);

            this.paramsService.params = {
               modalInstance: instance2,
               resolve: {
                  assetID: fullAsset.assetID,
                  locationID: fullAsset.locationID,
                  data: {
                     restrict: false,
                  },
               },
            };
         }
      });
   };

   public async lookUpPart(): Promise<void> {
      if (!this.featureUnlimitedParts && !this.featureLimitedNumber) {
         return;
      }

      const modalData: AddPartModalData = {
         title: this.lang().LookupPart,
         buttonText: this.lang().LookupPart,
         selectOne: true,
         message: this.lang().LookupPartMsg,
      };

      const part = await this.partsFacadeService.openAddPartModal(modalData);

      if (!part) {
         return;
      }
      if (!part[0]) {
         this.alertService.addAlert(
            this.lang().UnableToOpenPartFromQRCode,
            "warning",
            6000,
         );
         return;
      }
      const instance2 = this.modalService.open(PopPart);

      this.paramsService.params = {
         modalInstance: instance2,
         resolve: {
            partID: part[0].partID,
            locationID: part[0].locationID,
            data: {
               restrict: false,
            },
         },
      };
   }

   lookUpVendor = () => {
      if (!this.featureUnlimitedVendors && !this.featureLimitedNumber) {
         return;
      }

      const instance = this.modalService.open(PickVendors);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: "",
            title: this.lang().lookUpAVendor,
            data: {
               singleLocation: 0,
               selectOne: true,
               restrictToCred: false,
               iDontKnowOption: false,
            },
         },
      };

      instance.result.then((vendorIDArray) => {
         const vendor = this.manageVendor.getVendor(vendorIDArray[0]);
         assert(vendor);

         const instance2 = this.modalService.open(PopVendor);

         this.paramsService.params = {
            modalInstance: instance2,
            resolve: {
               vendorID: vendor.vendorID,
               locationID: vendor.locationID,
               data: {
                  restrict: false,
               },
            },
         };
      });
   };

   startWorkOrder = () => {
      this.startWOService.startWorkOrder();
   };

   reportProblem = () => {
      if (
         !this.credService.checkCredAnywhere(this.credService.Permissions.ReportAProblem)
      ) {
         this.alertService.addAlert(this.lang().cred45Fail, "danger", 10000);
         return;
      }

      const currentUser = this.manageUser.getCurrentUser();
      const code = currentUser.userInfo.customerBarcodeStr;

      if (this.manageLocation.getLocations().length == 1) {
         //if they only have 1 location send them to just that single location.
         const singleLocation = this.manageLocation.getLocations()[0].locationID;
         this.router.navigate(["/mobile-loc-problem", code, singleLocation]);
      } else {
         // $location.url("mobile-global-problem/" + code);
         const instance = this.modalService.open(PickLocationsModal);

         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               message: "",
               title: this.lang().WhichLocationSubmitRequest,
               data: {
                  selectOne: true,
                  buttonText: this.lang().Select,
               },
            },
         };

         instance.result.then((locations) => {
            if (locations) {
               const locationID = locations[0].locationID;

               this.router.navigate(["/mobile-loc-problem", code, locationID]);
            }
         });
      }
   };

   addAsset = () => {
      if (!this.credService.checkCredAnywhere(this.credService.Permissions.AddAssets)) {
         this.alertService.addAlert(this.lang().cred60Fail, "danger", 10000);
         return;
      }

      this.manageAssetServ.addAssetLogic(0, 0).then((newAssetID) => {
         if (!newAssetID) {
            return;
         }
         this.alertService.clearAllAlerts();

         const asset = this.manageAsset.getAsset(newAssetID);
         if (!asset) {
            return;
         }

         const instance = this.modalService.open(PopAsset);

         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               assetID: asset.assetID,
               locationID: asset.locationID,
               data: {
                  restrict: false,
               },
            },
         };
      });
   };

   login = () => {
      this.envRoutesService.getRoutes().then((envRoutes) => {
         window.location.href = envRoutes.frontendAuthServer;
      });
   };

   public hideSideMenu() {
      this.closeSideMenu.emit();
   }

   public showGlobalSearch() {
      const instance = this.modalService.open(GlobalSearchComponent);
      this.paramsService.params = {
         modalInstance: instance,
      };
   }

   private setCustomLogo(fileName: string | null) {
      const customerID = this.manageUser.getCurrentUser().userInfo.customerID;
      if (!fileName) {
         this.usingCustomLogo = false;
         this.customLogoURL = "";
         return;
      }
      this.customLogoURL = `viewFile.php?f=upload-${customerID}/customLogo/${fileName}`;
      this.usingCustomLogo = true;
   }

   lookupPO = () => {
      if (!this.featureUnlimitedPOs && !this.featureLimitedNumber) {
         return;
      }

      const instance = this.modalService.open(PickPOs);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               title: this.lang().PickPurchaseOrder,
               message: false,
               selectOne: true,
               showPOsWaitingToBeReceived: false,
               locationID: 0,
               allowCreate: true,
            },
         },
      };

      instance.result.then((result) => {
         if (result.length > 0) {
            this.popPO(result[0].poID);
         }
      });
   };

   popPO = (poID) => {
      const instance = this.modalService.open(PoComponent);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: { poID: poID },
         },
      };
   };

   checkCredFullArray = (credID) => {
      return this.credService.checkCredAnywhere(credID);
   };

   receivePOItems = () => {
      if (!this.featureUnlimitedPOs && !this.featureLimitedNumber) {
         return;
      }

      if (
         !this.credService.checkCredAnywhere(this.credService.Permissions.ReceivePOItems)
      ) {
         this.alertService.addAlert(this.lang().cred43Fail, "danger", 10000);
         return;
      }

      const instance = this.modalService.open(PickPOs);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               title: this.lang().PickPurchaseOrder,
               message: this.lang().WhichPOWouldYouLikeToReceive,
               selectOne: true,
               showPOsWaitingToBeReceived: true,
               locationID: 0,
               allowCreate: false,
            },
         },
      };

      instance.result.then((result) => {
         if (result.length > 0) {
            const poID = result[0].poID;
            const instance2 = this.modalService.open(ReceiveMobilePo);

            this.paramsService.params = {
               modalInstance: instance2,
               resolve: {
                  data: {
                     title: this.lang().WhichItemsDidYouReceive,
                     poID: poID,
                  },
               },
            };
         }
      });
   };

   protected scanQrCallback(code: string): void {
      this.qrCodeService.handleQrCodeDefault(code);
   }
}
