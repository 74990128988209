import { AsyncPipe, JsonPipe } from "@angular/common";
import {
   inject,
   ChangeDetectionStrategy,
   Component,
   Input,
   computed,
} from "@angular/core";
import { ModalService, PaginationComponent, PanelComponent } from "lim-ui";
import {
   BehaviorSubject,
   Subject,
   combineLatest,
   distinctUntilChanged,
   filter,
   map,
   share,
   startWith,
   switchMap,
} from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { GlobalSearchComponent } from "src/app/shared/components/global/globalSearch/globalSearch.component";
import { SortColumn } from "src/app/shared/components/global/sortColumnModal/sortColumn.element.component";
import {
   DataViewerPaginatorComponent,
   DataViewerStateService,
} from "src/app/shared/data-viewer";
import type { TaskTemplateColumnSortOptions } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.models";
import { TaskTemplatesApiService } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.service";
import { TaskTemplatesFacadeService } from "src/app/tasks/components/shared/services/task-templates-facade/task-templates-facade.service";
import { TaskTemplateListItemComponent } from "src/app/tasks/components/task-template-list-item/task-template-list-item.component";

@Component({
   selector: "global-search-pm-list",
   templateUrl: "./global-search-pm-list.component.html",
   styleUrls: ["./global-search-pm-list.component.scss"],
   standalone: true,
   imports: [
      PanelComponent,
      SortColumn,
      AsyncPipe,
      PaginationComponent,
      DataViewerPaginatorComponent,
      TaskTemplateListItemComponent,
      JsonPipe,
   ],
   providers: [DataViewerStateService, TaskTemplatesFacadeService],
   changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalSearchPmListComponent {
   @Input({ required: true }) search: string = "";

   protected reachedScheduleLimit: boolean = false;
   protected readonly limUiModal = inject(ModalService);
   private readonly taskTemplatesApi = inject(TaskTemplatesApiService);
   private readonly dataViewerState = inject(DataViewerStateService);
   private readonly taskTemplatesFacadeService = inject(TaskTemplatesFacadeService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   private readonly sortSubject = new BehaviorSubject<TaskTemplateColumnSortOptions>(
      "checklistName",
   );
   protected readonly sort$ = this.sortSubject.asObservable();
   private readonly isInitializedSubject = new Subject<void>();
   public readonly isInitialized$ = this.isInitializedSubject.asObservable();

   private readonly modalCloseEvents$ = this.limUiModal.closeEvents.pipe(
      filter(
         () =>
            this.limUiModal.getActiveModal()?.componentInstance instanceof
            GlobalSearchComponent,
      ),
   );

   protected readonly taskTemplatesResponse$ = combineLatest([
      this.dataViewerState.pagination$,
      this.sort$,
      this.isInitialized$,
      this.modalCloseEvents$.pipe(startWith(0)),
   ]).pipe(
      filter(() => this.search !== undefined),
      distinctUntilChanged(),
      switchMap(([pagination, sort]) =>
         this.taskTemplatesApi.getList({
            sort,
            pagination,
            params: {
               checklistTemplates: [1],
               columns: "assets,recurrences,schedules,assignment",
               excludeDeletedAssets: true,
               search: this.search,
            },
         }),
      ),
      share(),
   );

   protected viewModel$ = this.taskTemplatesResponse$.pipe(
      map((response) => ({ templates: response.data, total: response.total })),
   );

   public constructor() {
      this.initialize();
   }

   private async initialize() {
      this.reachedScheduleLimit =
         await this.taskTemplatesFacadeService.reachedCustomerScheduleLimit();
      this.isInitializedSubject.next();
   }

   protected sortChange(sortOrder: string): void {
      this.sortSubject.next(sortOrder as TaskTemplateColumnSortOptions);
   }
}
